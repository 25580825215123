export class Eventbus {
    constructor(name = 'main') {
        // make sure bus is only registered once
        if (window.EventBuses?.[name]) {
            return window.EventBuses[name];
        }

        if (!window.EventBuses) {
            window.EventBuses = {};
        }

        window.EventBuses[name] = this;

        this.name = name;
        this.events = {};
    }

    on(eventName, fn) {
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(fn);
    }

    off(eventName, fn) {
        if (this.events[eventName]) {
            this.events[eventName] = this.events[eventName].filter(
                (listener) => fn !== listener
            );
        }
    }

    trigger(eventName, ...data) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(function (fn) {
                fn(...data);
            });
        }
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    if (!import.meta.client) {
        return;
    }

    const bus = new Eventbus();
    nuxtApp.provide('eventbus', bus);
});
