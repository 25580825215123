<script setup>
const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    baseTimeout: {
        type: Number,
        default: 200,
    },
    maxChecks: {
        type: Number,
        default: -1,
    },
    spinnerClass: {
        type: String,
        default: 'h-16 w-16',
    },
    alt: {
        type: String,
        default: '',
    },
});

const src = computed(() => props.src);

const error = ref(false);
const loading = ref(true);
const checks = ref(0);
const timeout = ref(props.baseTimeout);

// Reset the timeout and checks when the image is found
const onImageFound = () => {
    loading.value = false;
    checks.value = 0;
    timeout.value = props.baseTimeout;
};

// Increase the timeout and checks when the image is not found
const onImageNotFound = () => {
    checks.value += 1;
    timeout.value += checks.value * props.baseTimeout;

    // If the max checks is reached, show an error
    if (props.maxChecks > 0 && checks.value >= props.maxChecks) {
        error.value = true;
        loading.value = false;
        return;
    }

    window.setTimeout(() => {
        checkImage();
    }, timeout.value);
};

// Check if the image exists
const checkImage = () => {
    loading.value = true;

    const img = new Image();
    img.onload = onImageFound;
    img.onerror = onImageNotFound;
    img.src = src.value;
};

watch(src, () => {
    checkImage();
});

onMounted(() => {
    checkImage();
});
</script>

<template>
    <ClientOnly>
        <span v-if="error" v-text="'Error'"/>
        <div v-else-if="loading" class="flex items-center justify-center">
            <CommonLoadingSpinner :class="spinnerClass" />
        </div>
        <img v-else :src="src" :alt="alt" />

        <template #fallback>
            <CommonLoadingSpinner :class="spinnerClass" />
        </template>
    </ClientOnly>
</template>
