export default function useAdminUrl() {
    const localePath = useLocalePath();

    const getSsrUrlParams = (ctx) => {
        if (!ctx) return {};

        const url = ctx.url;
        const urlParams = url.split('/').filter((value) => value);

        return {
            team: urlParams[1] || '',
            product: urlParams[2] || '',
        };
    };

    const urlParams = computed(() => {
        if (import.meta.server) {
            const { ssrContext } = useNuxtApp();

            return getSsrUrlParams(ssrContext);
        }

        const { params } = useRoute();
        return params;
    });

    // computed urlPath helper
    const teamPath = computed(() =>
        localePath(`/admin/${urlParams.value.team}`)
    );
    const urlPath = computed(() =>
        localePath(`/admin/${urlParams.value.team}/${urlParams.value.product}`)
    );

    const hasTeamOrProduct = computed(() => {
        return (
            [urlParams.value.team, urlParams.value.product].filter(
                (value) => value
            ).length > 1
        );
    });

    // TODO maybe use profile?
    const navigateToTeam = (teamUuid, path = null) => {
        return navigateToAdmin(teamUuid, urlParams.value.product, path);
    };

    const navigateToProduct = (productUuid, path = null) => {
        return navigateToAdmin(urlParams.value.team, productUuid, path);
    };

    const navigateToAdmin = (teamUuid, productUuid, path = null) => {
        const localizedBase = localePath(`/admin/${teamUuid}/${productUuid}`);

        if (path) {
            return navigateTo(`${localizedBase}/${path}`);
        }

        return navigateTo(localizedBase);
    };

    return {
        urlParams,
        teamPath,
        urlPath,
        hasTeamOrProduct,

        navigateToTeam,
        navigateToProduct,
        navigateToAdmin,
    };
}
