import { useTeamsStore } from '~/stores/teams';

export default function useFetchHeaders(
    baseHeaders = {},
    options = { useXsrf: false, watch: true }
) {
    const { public: runtimeConfig } = useRuntimeConfig();

    const { token } = useAuth({
        required: false,
    });

    const storeProducts = useProductStore();
    const storeTeams = useTeamsStore();

    const { urlParams } = useAdminUrl();

    const headers = ref(unref(baseHeaders) ?? {});

    if (baseHeaders['Content-Type'] === undefined) {
        delete headers.value['Content-Type'];
    }

    const refreshHeaders = () => {
        if (
            !headers.value['Content-Type'] &&
            baseHeaders['Content-Type'] !== undefined
        ) {
            headers.value['Content-Type'] = 'application/json';
        }

        if (!headers.value['Accept']) {
            headers.value['Accept'] = 'application/json';
        }

        if (runtimeConfig.platform.authToken) {
            headers.value['X-Context-Platform-Public-Auth-Token'] =
                runtimeConfig.platform.authToken;
        }

        if (storeProducts.currentProductUuid || urlParams.value.product) {
            headers.value['X-Context-Product'] =
                headers.value['X-Context-Product'] ||
                storeProducts.currentProductUuid ||
                urlParams.value.product;
        }

        if (storeTeams.currentTeamUuid || urlParams.value.team) {
            headers.value['X-Context-Team'] =
                headers.value['X-Context-Team'] ||
                storeTeams.currentTeamUuid ||
                urlParams.value.team;
        }

        if (token.value) {
            headers.value['Authorization'] = token.value.startsWith('Bearer')
                ? token.value
                : `Bearer ${token.value}`;
        } else if (!token.value && headers.value['Authorization']) {
            delete headers.value['Authorization'];
        }
    };

    if (options.watch) {
        watch(token, () => {
            refreshHeaders();
        });
    }

    return {
        headers,
        refreshHeaders,
    };
}
