import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default defineNuxtPlugin((nuxtApp) => {
    const { public: runtimeConfig } = useRuntimeConfig();
    const { echo: echoConfig, appHost } = runtimeConfig;

    if (!echoConfig.key || !echoConfig.enabled) {
        nuxtApp.provide('echo', {
            inactive: true,
        });

        return;
    }

    const { token } = useAuth();

    // eslint-disable-next-line no-unused-vars
    const authorizer = (channel, options) => {
        return {
            authorize: async (socketId, callback) => {
                if (!token.value) {
                    return callback('Not authenticated', null);
                }

                try {
                    const response = await $fetch(
                        `${appHost}/api/broadcasting/auth`,
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token.value}`,
                            },
                            body: JSON.stringify({
                                socket_id: socketId,
                                channel_name: channel.name,
                            }),
                        }
                    );

                    callback(null, response);
                } catch (error) {
                    console.error(error);
                    callback(error, null);
                }
            },
        };
    };

    // Remove protocol from host
    const wsHost =
        echoConfig.wsHost ?? appHost.replace(/^(http|https)?:\/\//, '');

    const options = {
        debug: true,
        broadcaster: 'reverb',
        // Host and port
        key: echoConfig.key,
        wsHost,
        wsPort: echoConfig.wsPort,
        wssPort: echoConfig.wssPort,
        // Tls/Encryption
        enabledTransports: ['ws', 'wss'],
        encrypted: echoConfig.encrypted,
        forceTLS: echoConfig.forceTls,
        // Auth related options
        authEndpoint: `${runtimeConfig.appHost}/api/broadcasting/auth`,
        authorizer,
    };

    const pusher = new Pusher(runtimeConfig.echo.key, {
        ...options,
        // Http
        httpHost: appHost,
        statsHost: appHost,
        disableStats: echoConfig.disableStats,
        cluster: '',
    });

    const echo = new Echo({
        ...options,
        client: pusher,
    });

    nuxtApp.provide('echo', echo);
});
