import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import type { ModuleOptions } from '..';
import type { App } from 'vue';

import beforeSendHook from '../helper/before-send-hook';

const initSentry = (config: ModuleOptions, vueApp: App) => {
    if (!import.meta.client || !vueApp) {
        console.info(
            'Sentry initialization skipped. Not running in the browser or Vue app is missing.'
        );
        return;
    }

    if (!config.dsn) {
        console.info('Sentry DSN is missing. Skipping Sentry initialization.');
        return;
    }

    Sentry.init({
        app: vueApp,
        environment: config.environment ?? 'prod',
        dsn: config.dsn,
        trackComponents: true,
        tracesSampleRate: config.tracesSampleRate || 1.0,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        replaysSessionSampleRate: 0.1,
        beforeSend: beforeSendHook,
    });
};

export default defineNuxtPlugin((nuxt) => {
    nuxt.hook('app:created', (vueApp) => {
        if (!nuxt.payload.config) {
            return;
        }

        const { public: publicConfig } = nuxt.payload.config;

        if (!publicConfig?.sentry) {
            return;
        }

        initSentry(publicConfig.sentry, vueApp);
    });
});
