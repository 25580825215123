export function useUserPermissions() {
    const { data } = useAuth();
    const { urlParams } = useAdminUrl();

    const userCapabilities = () => {
        if (!data.value) {
            return {};
        }

        return data.value.frontend_capabilities;
    };

    const userRoles = computed(() => {
        if (!data.value) {
            return [];
        }

        return data.value.roles;
    });

    const manageableProducts = computed(() => {
        return Object.entries(data.value?.product_management_access || {})
            .filter(([, value]) => value)
            .map(([key]) => key);
    });

    const canManageProducts = computed(() => {
        if (!data.value) {
            return false;
        }

        return manageableProducts.value.length > 0 || false;
    });

    const hasFrontendManagementAccess = computed(() => {
        if (!data.value) {
            return false;
        }

        return data.value.frontend_management_access || false;
    });

    const checkProductAccess = (productUuid) => {
        if (!productUuid || !data.value) {
            return false;
        }
        return data.value.product_uuids?.includes(productUuid) || false;
    };

    const canAccessCurrentProduct = computed(() => {
        if (!data.value) {
            return false;
        }

        return checkProductAccess(urlParams.value.product);
    });

    const checkProductManagementAccess = (product) => {
        if (!data.value) {
            return false;
        }

        return data.value.product_management_access[product] || false;
    };

    return {
        userCapabilities,
        userRoles,
        manageableProducts,
        canManageProducts,
        checkProductAccess,
        canAccessCurrentProduct,
        checkProductManagementAccess,
        hasFrontendManagementAccess,
    };
}
