// TODO needs refactoring
/**
 * Wrapper for nuxt internal useFetch composeable
 * with opiniated default we migth always use
 *
 * @param {string} url
 * @param {string|Ref<string>} key
 * @param {Object} options
 * @returns {Promise<AsyncData<DataT>>}
 */
export default function useApiFetch(url, key = '', options = {}) {
    const { public: runtimeConfig } = useRuntimeConfig();

    // compute url we want to fetch
    const fetchUrl = computed(() => {
        let _url = unref(url);

        if (_url.startsWith('http')) {
            return _url;
        }

        if (!_url.startsWith('/')) {
            _url = `/${_url}`;
        }

        const baseURL = options.baseURL ?? runtimeConfig.appHost;

        return `${baseURL}${_url}`;
    });

    const { headers, refreshHeaders } = useFetchHeaders(options.headers);

    // eslint-disable-next-line no-unused-vars
    let { key: _, headers: __, watchUrl: ___, ...reqOptions } = options;

    if (options.watchUrl) {
        return useFetch(fetchUrl, {
            key,
            ...reqOptions,
            onRequest: ({ options }) => {
                refreshHeaders();
                options.headers = headers.value;
            },
        });
    }

    return useFetch(fetchUrl.value, {
        key,
        ...reqOptions,
        onRequest: (context) => {
            const { options: _opts } = context;

            refreshHeaders();
            _opts.headers = headers.value;

            if (options.onRequest && typeof options.onRequest === 'function') {
                options.onRequest(context);
            }
        },
    });
}
