// TODO marked for removal
/**
 * Wrapper for nuxt internal useFetch composeable
 * with opiniated default we migth always use
 *
 * @param {string} url
 * @param {string|Ref<string>} key
 * @param {Object} options
 * @returns {Promise<AsyncData<DataT>>}
 */
export default function useLazyApiFetch(url, key = '', options = {}) {
    const { public: runtimeConfig } = useRuntimeConfig();

    // compute url we want to fetch
    const fetchUrl = computed(() => {
        let _url = unref(url);

        if (_url.startsWith('http')) {
            return _url;
        }

        if (!_url.startsWith('/')) {
            _url = `/${_url}`;
        }

        const baseURL = options.baseURL ?? runtimeConfig.appHost;

        return `${baseURL}${_url}`;
    });

    const { headers, refreshHeaders } = useFetchHeaders(options.headers);

    // eslint-disable-next-line no-unused-vars
    let { key: _, headers: __, ...reqOptions } = options;

    return useLazyFetch(fetchUrl.value, {
        onRequest: ({ options }) => {
            refreshHeaders();
            options.headers = headers.value;
        },
        key,
        ...reqOptions,
    });
}
