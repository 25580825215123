/**
 * Injexts global '$apiFetch' helper
 */
export default defineNuxtPlugin(async (nuxtApp) => {
    const { public: runtimeConfig } = useRuntimeConfig();

    nuxtApp.provide(
        'apiFetch',
        $fetch.create({
            baseURL: `${runtimeConfig.appHost}`,
        })
    );
});
