export const useCurrentPlatformStore = defineStore('current-platform', () => {
    const { data: platform, status } = useApiFetch(
        '/api/v1/current-platform',
        `current-platform`,
        {
            transform: (data) => {
                return data?.data ?? data;
            },
        }
    );

    const platformLogo = computed(() => {
        if (status.value === 'pending' || !platform.value?.logo) {
            return {};
        }

        const logo = platform.value.logo;

        return {
            src: logo.src,
            alt: logo.alt || platform.value.name,
        };
    });

    const externalLinks = computed(() => {
        if (status.value === 'pending' || !platform.value) {
            return {};
        }

        return {
            siteNoticeUrl: platform.value.site_notice_url,
            privacyUrl: platform.value.privacy_url,
            termsUrl: platform.value.terms_url,
            serviceEmail: platform.value.service_email,
            phoneNumber: platform.value.phone_number,
        };
    });

    const hasLinks = computed(() => {
        return Object.values(externalLinks.value).some((link) => Boolean(link));
    });

    return {
        platform,
        platformLogo,
        externalLinks,
        hasLinks,
    };
});
