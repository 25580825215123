import typeOf from 'just-typeof';

class Notify {
    success(message, headline = null) {
        this.notify(message, 'success', headline);
    }

    error(message, headline = null) {
        this.notify(message, 'error', headline);
    }

    warning(message, headline = null) {
        this.notify(message, 'warning', headline);
    }

    info(message, headline = null) {
        this.notify(message, 'info', headline);
    }

    notify(message, type, headline = null) {
        if (typeOf(message) === 'array') {
            message.forEach((msg) => {
                this.notify(msg, type, headline);
            });

            return;
        }

        this.dispatch({
            message,
            type,
            headline,
        });
    }

    dispatch(message) {
        if (!import.meta.client) {
            return;
        }

        window.dispatchEvent(
            new CustomEvent('new-notification', {
                detail: message,
            })
        );
    }
}

export default defineNuxtPlugin((app) => {
    app.provide('notify', new Notify());
});
