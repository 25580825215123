import DE from './DE.vue';
import EN from './EN.vue';
import HU from './HU.vue';
import NL from './NL.vue';
import PL from './PL.vue';
import RO from './RO.vue';

export default {
    DE,
    EN,
    HU,
    NL,
    PL,
    RO,
};
