export default defineNuxtPlugin(() => {
    if (!import.meta.client) {
        return;
    }

    const runtimeConfig = useRuntimeConfig();

    const { learningAwsAssetsUrl } = runtimeConfig.public.static;

    /**
     * Define window function to retrieve build js from aws static bucket
     *
     * @param {string} filename
     */
    window.__awsStaticUrl = (filename) => {
        const url = new URL(learningAwsAssetsUrl);
        url.pathname = `/player/${filename}`;
        return url.toString();
    };
});
