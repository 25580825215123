export const useProductStore = defineStore('productStore', () => {
    const { urlParams } = useAdminUrl();

    const initialized = ref(false);
    const products = ref([]);
    const licensePacks = ref({});

    const unloading = ref(false);

    const unload = () => {
        unloading.value = true;

        products.value = [];
        initialized.value = false;
        licensePacks.value = {};

        unloading.value = false;
    };

    const currentProduct = computed(() => {
        return products.value.find((item) => item.isActive);
    });

    const productCodes = computed(() =>
        products.value.map((item) => item.foreign_product_code)
    );

    const currentProductUuid = computed(() => {
        return currentProduct.value?.uuid ?? null;
    });

    const currentProductCode = computed(() => {
        return currentProduct.value?.foreign_product_code ?? null;
    });

    const currentProductType = computed(() => {
        return currentProduct.value?.type ?? null;
    });

    const currentProductLicenses = computed(() => {
        if (!initialized.value) {
            return {};
        }

        return (
            licensePacks.value?.[currentProduct.value?.foreign_product_code] ??
            {}
        );
    });

    const currentProductLicensesCount = computed(() => {
        if (!initialized.value) {
            return 0;
        }

        return (
            licensePacks.value?.[currentProduct.value?.foreign_product_code]
                ?.in_use_licenses_count ?? 0
        );
    });

    const productLicensesLoaded = computed(() => {
        return (
            initialized.value &&
            Object.keys(licensePacks.value ?? {}).length > 0
        );
    });

    const updateProducts = (items) => {
        products.value = items;
    };

    const addActiveLicense = (count = 1) => {
        if (!currentProductLicenses.value) {
            return;
        }

        currentProductLicenses.value.in_use_licenses_count += count;
    };

    const subtractActiveLicense = (count = 1) => {
        if (!currentProductLicenses.value) {
            return;
        }

        const after =
            currentProductLicenses.value.in_use_licenses_count - count;

        if (after < 0) {
            currentProductLicenses.value.in_use_licenses_count = 0;
        }

        currentProductLicenses.value.in_use_licenses_count = after;
    };

    const { data: productOptions, execute: loadProductLicenses } = useApiFetch(
        `/api/v1/selects/product-licenses`,
        'product-licenses',
        {
            immediate: false,
        }
    );

    const productsWithLicenses = computed(() => {
        if (!productOptions.value?.data) {
            return [];
        }

        return productOptions.value.data;
    });

    const { execute: _loadProducts } = useApiFetch(
        '/api/v1/products',
        'products',
        {
            immediate: false,
            onResponse: ({ response }) => {
                if (!response.ok) {
                    return;
                }

                const { data } = response._data;

                updateProducts(data);
                setActive(urlParams.value?.product);

                initialized.value = true;
            },
        }
    );

    const loadProducts = async () => {
        if (initialized.value) {
            return;
        }

        await _loadProducts();
    };

    const { execute: updateLastUsed } = useApiFetch(
        '/api/v1/update-last-used',
        '',
        {
            body: {
                type: 'product',
                value: currentProductUuid,
            },
            method: 'PATCH',
            immediate: false,
            watch: false,
        }
    );

    const {
        execute: _loadLicenses,
        status: loadStatus,
        error: loadError,
    } = useApiFetch(`/api/v1/licenses`, 'licenses', {
        immediate: false,
        onResponse: ({ response }) => {
            if (!response.ok) {
                return;
            }
            const { data } = response._data;

            licensePacks.value = data?.license_packs ?? {};
        },
    });

    watch(currentProductUuid, (uuid) => {
        if (!uuid) {
            return;
        }

        updateLastUsed();
    });

    const setActive = (uuid) => {
        products.value = products.value.map((item, key) => {
            return {
                ...item,
                index: key,
                isActive: uuid ? item.uuid === uuid : key === 0,
            };
        });

        updateLastUsed();
    };

    const loadLicenses = async (force = false) => {
        if (Object.keys(licensePacks.value).length === 0 || force) {
            await _loadLicenses();
        }
    };

    return {
        products,
        productCodes,
        initialized,
        licensePacks,
        currentProduct,
        currentProductUuid,
        currentProductCode,
        currentProductType,
        currentProductLicenses,
        productsWithLicenses,
        setActive,
        updateProducts,
        currentProductLicensesCount,
        productLicensesLoaded,
        addActiveLicense,
        subtractActiveLicense,
        loadProducts,
        loadLicenses,
        loadProductLicenses,
        unload,
        loadStatus,
        loadError,
    };
});
