export default defineNuxtPlugin((nuxtApp) => {
    const { data: profile, status } = useAuth();
    const { public: publicConfig } = useRuntimeConfig();
    const { platform } = storeToRefs(useCurrentPlatformStore());

    const i18nCookie = useCookieLocale();

    const route = useRoute();
    const switchLocalePath = useSwitchLocalePath();

    /** @type {string?} - the locale for the platform */
    const platformLocale = computed(() => platform.value?.locale);

    /** @type {string?} - the locale passed to the url */
    const routeLocale = computed(() => {
        return route.query.locale;
    });

    /** @type {string?} - the locale for the user */
    const profileLocale = computed(() => {
        if (status.value !== 'authenticated') {
            // we use the route locale if the user is not authenticated
            return routeLocale.value;
        }

        return profile.value?.locale;
    });

    const saveSwitchLocale = (newLocale) => {
        // if the locale is already set, or there is no locale to switch to, return
        if (!newLocale || newLocale === nuxtApp.$i18n?.locale.value) {
            return;
        }
        // if the i18n strategy is no_prefix, we can just set the locale
        if (nuxtApp.$i18n?.strategy === 'no_prefix') {
            nuxtApp.$i18n?.setLocale(newLocale);
            reloadNuxtApp();

            return;
        }

        const path = switchLocalePath(newLocale);
        navigateTo(path);
    };

    const refreshLocale = () => {
        // get the locale to switch to - priority is profile or url param, then platform, then fallback
        const switchTo =
            profileLocale.value ??
            platformLocale.value ??
            publicConfig.fallbackLocale;

        saveSwitchLocale(switchTo);
    };

    // provide locale
    nuxtApp.provide('switchLocale', saveSwitchLocale);

    let unwatchPlatform;

    // watch for changes in the profile locale, and update the locale
    watch(profileLocale, (newValue) => {
        if (status.value === 'loading' || !newValue) {
            return;
        }

        refreshLocale();

        // if the platform locale is still being watched, stop watching it
        if (unwatchPlatform) {
            unwatchPlatform();
        }
    });

    // if the i18n cookie is set, we don't need to do anything
    if (i18nCookie.value) {
        return;
    }

    // watch for changes in the platform locale, and update the locale
    unwatchPlatform = watch(platformLocale, () => {
        refreshLocale();

        unwatchPlatform();
    });
});
