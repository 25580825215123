export const useTeamsStore = defineStore('teamsStore', () => {
    const { urlParams } = useAdminUrl();

    const teams = ref([]);
    const mainTeam = ref([]);

    const initialized = ref(false);

    const activeTeam = customRef((track, trigger) => {
        let _activeTeam = null;

        return {
            get() {
                track();

                const v = _activeTeam;
                _activeTeam = null;

                return v;
            },
            set(value) {
                _activeTeam = value;
                trigger();
            },
        };
    });

    const currentTeam = computed(() => {
        return !initialized.value
            ? {}
            : teams.value.find((item) => item.isActive);
    });

    const isCurrentTeamMain = computed(() => {
        return currentTeam.value?.is_main ?? false;
    });

    const currentTeamUuid = computed(() => {
        return currentTeam.value?.uuid ?? null;
    });

    const hasLegacyCertificates = computed(() => {
        return currentTeam.value?.has_legacy_certificates ?? false;
    });

    const setActiveTeam = (uuid = null, array = null) => {
        if (uuid === null) {
            uuid = urlParams.value.team;
        }

        if (array === null) {
            array = teams.value;
        }

        teams.value = array.map((item, key) => {
            return {
                ...item,
                index: key,
                isActive: uuid ? item?.uuid === uuid : key === 0,
            };
        });
    };

    const { execute: _loadMainTeam } = useLazyApiFetch(
        '/api/v1/main-team',
        'mainTeam',
        {
            immediate: false,
            onResponse: ({ response }) => {
                if (!response.ok) {
                    return;
                }

                const { data } = response._data;
                mainTeam.value = data;
            },
        }
    );

    const loadMainTeam = () => {
        return new Promise((resolve) => {
            _loadMainTeam().then(() => {
                resolve(mainTeam.value);
            });
        });
    };

    const { execute: _loadTeams } = useApiFetch('/api/v1/teams', 'teams', {
        immediate: false,
        onResponse: ({ response }) => {
            if (!response.ok) {
                return;
            }

            const { data } = response._data;

            setActiveTeam(activeTeam.value, data);

            loadMainTeam();
            initialized.value = true;
        },
    });

    const loadTeams = (route) => {
        activeTeam.value = route?.params?.team ?? null;

        _loadTeams();
    };

    const unload = () => {
        teams.value = [];
        mainTeam.value = [];
        initialized.value = false;
    };

    return {
        teams,
        mainTeam,
        currentTeam,
        currentTeamUuid,
        hasLegacyCertificates,
        loadTeams,
        loadMainTeam,
        setActiveTeam,
        isCurrentTeamMain,
        unload,
    };
});
