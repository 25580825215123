<template>
    <div class="relative">
        <div class="spinner"/>
    </div>
</template>

<style scoped>
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner 0.6s linear infinite;
}
</style>
